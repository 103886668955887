.h1_black {
  color: #000;
  text-align: center;
  font-size: 55px;
  font-family: "Libre Baskerville";
  font-weight: 700;
  line-height: 55px;
}

.h1_red {
  color: #df181a;
  text-align: center;
  font-size: 55px;
  font-family: "Libre Baskerville";
  font-weight: 700;
  line-height: 55px;
}

.service_para {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-family: Mulish;
  line-height: 40px;
}

.icons_h5 {
  color: #000;
  font-size: 24px;
  font-family: Libre Baskerville;
  font-weight: 700;
}
.icons_p {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-family: Mulish;
  font-weight: 500;
  line-height: 32px;
}

@media (max-width: 550px) {
  .h1_black {
    font-size: 40px !important;
    line-height: 40px !important;
  }

  .h1_red {
    font-size: 40px !important;
    line-height: 40px !important;
  }
  .service_para {
    font-size: 18px !important;
    line-height: 30px !important;
  }
}
@media (max-width: 375px) {
  .h1_black {
    font-size: 35px !important;
    line-height: 32px !important;
  }

  .h1_red {
    font-size: 35px !important;
    line-height: 32px !important;
  }
  .service_para {
    font-size: 19px !important;
    line-height: 19px !important;
  }
}
