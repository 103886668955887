.navbar {
  display: flex;
  justify-content: space-between;
  /* padding: 0.3rem 1rem; */
  align-items: center;
  box-shadow: 1px 1px 10px 0px #000;
  /* background-color: #f2f2f2; */
  padding: 10px 20px;
}

.navbar-logo img {
  height: 45px;
}

.navbar-menu ul {
  align-items: center;
  justify-content: center;
  list-style: none;
  display: flex;
  flex-direction: column;
}

.navbar-menu ul li {
  margin: 7px 0px;
  /* margin-right: 0px !important; */
}

.navbar-menu ul li a {
  text-decoration: none;
  color: #000000;
  position: relative;
  font-size: 1.3rem;
  /* font-family: "Spirax", cursive; */
  /* font-weight: 750; */
}

.navbar-menu ul li a:hover {
  text-decoration: underline;
}

.navbar-menu-button {
  cursor: pointer;
}

.navbar-menu-button svg {
  font-size: 24px;
  color: #333;
}

.navbar-menu {
  display: none;
}
.iconsfont {
  margin-right: 2rem;
  font-size: 1.5rem;
  opacity: 0.2;
  color: #e84524;
}
.iconsfont:hover {
  opacity: 1;
  color: #e84524;
}
.open {
  display: block;
  position: absolute;
  z-index: 9999;
  width: 100%;
  top: 3.8rem;
  background: #ffffff;
  align-items: center;
  justify-content: center;
  left: 0;
  animation: slideIn 0.8s ease-in-out forwards;
}
@keyframes slideIn {
  0% {
    transform: translateX(-100%); /* Start the animation from the left side */
  }
  100% {
    opacity: 1; /* Make the navigation bar fully visible */
    transform: translateX(0); /* Slide the navigation bar to the right side */
  }
}
/* Add the following styles to create a dropdown menu */
.navbar-menu ul li:hover > ul {
  display: block;
}

/* Update the existing styles for the dropdown menu */
.navbar-menu ul ul {
  display: none;
  position: absolute;
  top: 50%;
  left: 0;
  background-color: #f2efe9;
  min-width: 200px;
  padding: 10px;
  box-shadow: 1px 1px 10px 0px #000;
  columns: 2; /* Divide the dropdown items into two columns */
  column-gap: 20px; /* Add gap between the columns */
}

.navbar-menu ul ul li {
  margin-bottom: 5px;
  list-style: none; /* Remove default bullet points */
  display: inline-block; /* Display the dropdown items horizontally */
  width: 100%; /* Set the width of each item to 100% */
}

.navbar-menu ul ul li a {
  font-size: 1rem;
}

.navbar-menu ul ul li a:hover {
  text-decoration: underline;
}

@media (min-width: 768px) {
  .navbar-menu-button {
    display: none;
  }
  .navbar-menu {
    display: block;
    position: static;
    background-color: transparent;
    width: auto;
    padding: 0;
  }

  .navbar-menu ul {
    flex-direction: row;
    align-items: center;
  }

  .navbar-menu ul li {
    margin-bottom: 0;
    margin-right: 20px;
  }
}
