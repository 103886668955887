.work_bg {
  background-image: url("../assets/images/work-bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  /* opacity: 0.3499999940395355; */
  background: #fceaea;
}

.span1_work_h1 {
  color: #000;
  text-align: center;
  font-size: 55px;
  font-family: Libre Baskerville;
  font-weight: 700;
  line-height: 55px;
  text-transform: capitalize;
}

.span2_work_h1 {
  color: #d92526;
  text-align: center;
  font-size: 55px;
  font-family: Libre Baskerville;
  font-weight: 700;
  line-height: 55px;
  text-transform: capitalize;
}

.work_h3 {
  color: #000;
  font-size: 36px;
  font-family: Libre Baskerville;
  font-weight: 700;
  line-height: 55px;
}

.work_h6 {
  color: #df181a;
  font-size: 25px;
  font-family: Mulish;
  font-weight: 600;
}
.work_text {
  color: #000;
  font-size: 18px;
  font-family: Mulish;
  line-height: 35px;
}
.padd-right {
  padding-right: 40px;
  padding-left: 60px;
  padding-top: 60px;
}

.marg {
  margin-top: 70px;
  margin-bottom: 70px;
}

.padd-left {
  padding-right: 60px;
  padding-top: 75px;
}

.bottom {
  margin-bottom: 60px;
}

@media (max-width: 550px) {
  .padd-right {
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 10px;
  }
  .padd-left {
    padding-right: 20px;
    padding-top: 20px;
  }
  .span1_work_h1 {
    font-size: 40px !important;
    font-weight: 700;
    line-height: 40px;
  }
  .span2_work_h1 {
    font-size: 40px !important;
    font-weight: 700;
    line-height: 40px;
  }
  .work_h3 {
    font-size: 26px !important;
    font-weight: 700;
    line-height: 36px;
  }
  .work_text {
    color: #000;
    font-size: 17px;
    font-family: Mulish;
    line-height: 28px;
  }
}
