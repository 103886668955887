.h1_black {
  color: #000;
  text-align: center;
  font-size: 55px;
  font-family: Libre Baskerville;
  font-weight: 700;
  line-height: 55px;
}

.h1_red {
  color: #df181a;
  text-align: center;
  font-size: 55px;
  font-family: Libre Baskerville;
  font-weight: 700;
  line-height: 55px;
}

.program_text {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-family: Mulish;
  line-height: 40px;
}

.row4 {
  height: 400px;
}

.row8 {
  height: 400px;
}

.row2 {
  height: 150px;
  margin-bottom: 10px;
}

.col1 {
  margin-right: 5px;
  width: 49%;
  height: 180px;
}

.col2 {
  margin-left: 5px;
  width: 49%;
  height: 180px;
}

.lastrow {
  width: 99% !important;
  height: 165px;
  margin-top: 35%;
}

.gap {
  margin-bottom: 20px;
  margin-top: 20px;
}

/* Media Queries */
@media (max-width: 768px) {
  .row4,
  .row8 {
    height: auto;
  }

  .lastrow {
    margin-top: 20px;
  }

  .col1,
  .col2 {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
  .imagespad img {
    margin: 0.5rem;
  }
  .order2 {
    order: 2;
  }
}
