.marg1 {
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: "center";
}
.marg2 {
  margin-top: 40px;
  margin-bottom: 130px;
  text-align: center;
}

.app_h1_black {
  color: #000;
  text-align: center;
  font-size: 55px;
  font-family: Libre Baskerville;
  font-weight: 700;
  line-height: 55px;
  text-transform: capitalize;
}

.app_h1-red {
  color: #dd1b1d;
  text-align: center;
  font-size: 55px;
  font-family: Libre Baskerville;
  font-weight: 700;
  line-height: 55px;
  text-transform: capitalize;
}

.app_text {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-family: Mulish;
  font-weight: 500;
  line-height: 40px;
  margin-left: 8rem;
  margin-right: 8rem;
}

@media (max-width: 550px) {
  .marg1 {
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: "center";
  }
  .marg2 {
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
  }

  .app_h1_black {
    color: #000;
    text-align: center;
    font-size: 40px !important;
    font-family: Libre Baskerville;
    font-weight: 700;
    line-height: 40px;
    text-transform: capitalize;
  }
  .app_h1-red {
    color: #dd1b1d;
    text-align: center;
    font-size: 40px !important;
    font-family: Libre Baskerville;
    font-weight: 700;
    line-height: 40px;
    text-transform: capitalize;
  }
  .app_text {
    color: #000;
    text-align: center;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
    font-size: 18px;
    font-family: Mulish;
    font-weight: 500;
    line-height: 35px;
  }
}
