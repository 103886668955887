.background-img {
  background-image: url("../assets/images/img3.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  /* opacity: 0.3499999940395355; */
  background: #fceaea;
}

.h3_black {
  color: #000;
  font-size: 30px;
  font-family: Libre Baskerville;
  font-weight: 700;
  line-height: 70px;
}

.h3_red {
  color: #df181a;
  font-size: 30px;
  font-family: Libre Baskerville;
  font-weight: 700;
  line-height: 70px;
}

.text {
  color: #000;
  font-size: 18px;
  font-family: Mulish;
  line-height: 35px;
}

.radio_btn {
  color: #000;
  font-size: 18px;
  font-family: Mulish;
  font-weight: 500;
  line-height: 36px;
}

.padd {
  padding-top: 7rem !important;
  padding-bottom: 7rem !important;
}

@media (max-width: 550px) {
  .h3_black {
    color: #000;
    font-size: 25px;
    line-height: 50px;
  }

  .h3_red {
    color: #df181a;
    font-size: 25px;
    line-height: 50px;
  }
  .text {
    color: #000;
    font-size: 16px;
    font-family: Mulish;
    line-height: 24px;
  }
  .radio_btn {
    color: #000;
    font-size: 15px !important;
    font-family: Mulish;
    font-weight: 500;
    line-height: 25px !important;
  }
}
