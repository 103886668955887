.rowClass {
  justify-content: center;
  background: red;
  align-items: center;
}
.rowFluid {
  justify-content: space-between;
}
.rowCol1 {
  align-items: center;
  justify-content: end;
  display: flex;
  white-space: nowrap;
}

.rowCol1 a {
  width: 19.108px;
}
.rowCol2 {
  display: flex;
  justify-content: center;
  color: #fff;
  font-size: 16px;
  font-family: "Mulish";
}

.rowCol1 a img {
  width: 20px;
  height: 15px;
}
.rowCol3 {
  white-space: nowrap;
  color: #fff;
  font-size: 18px;
  font-family: "Mulish";
  font-weight: 600;
}

@media (max-width: 550px) {
  .rowFluid {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .rowCol1 {
    align-items: center;
    justify-content: center;
    display: flex;
    white-space: nowrap;
  }
  .rowCol1 a img {
    width: 15px;
    height: 15px;
  }
  .rowCol3 {
    text-align: center;
  }
}
