.blog_bg {
  background-image: url("../assets/images/blog-bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  /* opacity: 0.3499999940395355; */
  background: #fceaea;
}

.blog_h1_red {
  color: #000;
  text-align: center;
  font-size: 55px;
  font-family: Libre Baskerville;
  font-weight: 700;
  line-height: 55px;
}

.blog_h1_black {
  color: #df181a;
  text-align: center;
  font-size: 55px;
  font-family: Libre Baskerville;
  font-weight: 700;
  line-height: 55px;
}

.blog_text {
  color: #000;
  text-align: center;
  font-size: 18px;
  font-family: Mulish;
  line-height: 40px;
}
.space {
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: "center";
}
.para-space {
  margin-left: 1rem;
  text-align: justify;
  margin-right: 1rem;
}
.spacing {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
@media (max-width: 550px) {
  .blog_h1_red {
    color: #000;
    text-align: center;
    font-size: 40px !important;
    font-family: Libre Baskerville;
    font-weight: 700;
    line-height: 40px !important;
  }

  .blog_h1_black {
    color: #df181a;
    text-align: center;
    font-size: 40px !important;
    font-family: Libre Baskerville;
    font-weight: 700;
    line-height: 40px !important;
  }

  .blog_text {
    color: #000;
    text-align: center;
    font-size: 16px;
    font-family: Mulish;
    line-height: 25px;
  }
}
