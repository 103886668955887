.content-bgImg {
  background-image: url("../assets/images/img1.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 98vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-heading {
  color: #fff;
  text-align: center;
  font-size: 60px !important;
  font-family: "Libre Baskerville" !important;
  font-weight: 700 !important;
  line-height: 100px !important;
}

.banner-text {
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-family: Mulish;
  line-height: 40px;
}

.banner_btn {
  color: #000 !important;
  text-align: center;
  font-size: 18px !important;
  font-family: Mulish;
  font-weight: 700 !important;
  text-transform: uppercase !important ;
  background-color: #fff !important;
}
.content_ctr {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media (max-width: 768px) {
  .banner-heading {
    color: #fff;
    text-align: center;
    font-size: 20px !important;
    font-family: "Libre Baskerville" !important;
    font-weight: 700 !important;
    line-height: 50px !important;
  }
}
